import Layout from "../components/layout/Layout";
import { Card, Text, Button } from "@uitk/react";
import { RouteDisplay } from "../components/navigation/RouteDisplay";
import { PATHS } from "../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export function MapManagment() {
  const editMap = () => {
    location.href = PATHS.editMapMapValueDefinition.url;
  };
  return (
    <Layout>
      <div className="grid">
        <RouteDisplay pageRouteDisplay={[PATHS.mapManagement]} />

        <div className="row">
          <div className="col">
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet. Proin gravida dolor sit amet lacus
              accumsan et viverra justo commodo. Proin sodales pulvinar tempor.
              Cum sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget
              odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Aenean euismod bibendum laoreet.
            </Text>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Card
              subheader={<h2 className="flex-grow-1">Mapping</h2>}
              className="text-center me-4"
              media={{
                img: (
                  <div className="mt-4 card-icon">
                    <FontAwesomeIcon
                      icon={solid("network-wired")}
                      className="icon-link"
                    />
                  </div>
                ),
              }}
            >
              <div className="d-flex align-items-center flex-grow-1 flex-column">
                <div className="d-flex align-items-center mb-2">
                  <Button
                    variant="ghost"
                    icon={<FontAwesomeIcon icon={solid("plus")} />}
                  >
                    Create a New Map
                  </Button>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Button
                    onPress={editMap}
                    variant="ghost"
                    icon={<FontAwesomeIcon icon={solid("pencil")} />}
                  >
                    Edit or Clone a Map
                  </Button>
                </div>
                <div>
                  <span>
                    <Button
                      variant="ghost"
                      icon={<FontAwesomeIcon icon={solid("folder-open")} />}
                    >
                      Open a Template
                    </Button>
                  </span>
                </div>
              </div>
            </Card>
          </div>
          <div className="col">
            <Card
              subheader={<h2 className="flex-grow-1">Manage Templates</h2>}
              className="text-center me-4"
              media={{
                img: (
                  <div className="mt-4 card-icon">
                    <FontAwesomeIcon
                      icon={solid("desktop")}
                      className="icon-link"
                    />
                  </div>
                ),
              }}
            >
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                accumsan et viverra justo
              </Text>
            </Card>
          </div>
          <div className="col">
            <Card
              subheader={<h2 className="flex-grow-1">Map Lookup</h2>}
              className="text-center me-4"
              media={{
                img: (
                  <div className="mt-4 card-icon">
                    <FontAwesomeIcon
                      icon={solid("magnifying-glass")}
                      className="icon-link"
                    />
                  </div>
                ),
              }}
            >
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                accumsan et viverra justo
              </Text>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
}
