import PropTypes from "prop-types";
import { Button, Dialog, LoadingIndicator } from "@uitk/react";

export default function SubmissionPopup(props) {
  const renderSubmissionErrors = () => {
    const errors = [];
    props.submissionErrors.forEach((element, index) => {
      errors.push(
        <p key={index} className="text-danger">
          {element}
        </p>
      );
    });
    return errors;
  };

  return (
    <>
      {props.isOpen ? (
        <Dialog
          isOpen={props.isOpen}
          onClose={props.onClose}
          title={props.submissionHeader}
          titleAs="h2"
        >
          <Dialog.Body>
            <p>{props.submissionSubText}</p>
            {renderSubmissionErrors()}
          </Dialog.Body>
          <Dialog.Actions>
            <Button onPress={props.onClose}>Done</Button>
          </Dialog.Actions>
        </Dialog>
      ) : (
        <div className="popup-background">
          <LoadingIndicator size={"l"} loading={true} centerSpinner={true} />
        </div>
      )}
    </>
  );
}

SubmissionPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  submissionHeader: PropTypes.string.isRequired,
  submissionSubText: PropTypes.string.isRequired,
  submissionErrors: PropTypes.array.isRequired,
};
