import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export function RouteDisplay(props) {
  return (
    <div className="row">
      <div className="col">
        <a className="optum-orange color-link" href="/">
          <span className="home-icon">
            <FontAwesomeIcon icon={solid("home")} />
          </span>
        </a>
        <span> / </span>
        {props.pageRouteDisplay.map((value, index) => {
          // Alter class for last element
          if (index === props.pageRouteDisplay.length - 1) {
            return (
              <a key={index} className="color-link" href={value.url}>
                <span>{value.displayName}</span>
              </a>
            );
          } else {
            return (
              <span key={index}>
                <a className="optum-orange color-link" href={value.url}>
                  <span>{value.displayName}</span>
                </a>
                <span> / </span>
              </span>
            );
          }
        })}
      </div>
    </div>
  );
}

RouteDisplay.propTypes = {
  pageRouteDisplay: PropTypes.array,
};
