import { Footer } from "@uitk/react";
import Logo from "../../assets/logos/LogoDarkText.svg";

export function CmsFooter() {
  const linkConfig = {
    links: [
      {
        url: "/",
        label: "Home",
      },
      {
        url: "https://www.optum.com/contact.html",
        label: "Help",
        target: "_blank",
      },
    ],
  };
  return (
    <div className="grid">
      <div className="row">
        <div className="col">
          <Footer
            className={"cms-footer"}
            linkConfig={linkConfig}
            logoContent={
              <img
                src={Logo}
                alt="optum logo"
                className="uitk-footer__logo--small uitk-footer__logo--medium uitk-footer__logo--large "
              />
            }
          >
            This is placeholder text. This is where you should place your legal
            text and/or privacy notice. It is recommended that you consult with
            your legal advisors for text that is relevant and adequate for your
            context and use. UI Toolkit cannot offer advice on legal text or
            privacy notices, this is outside our remit as a design system. Thank
            you for using UI Toolkit.
          </Footer>
        </div>
      </div>
    </div>
  );
}
