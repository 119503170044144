import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export function ProgressWizard(props) {
  const renderWizardCircles = () => {
    const result = [];
    for (let i = 1; i < 6; i++) {
      // Initial classes
      const circleClassNames = ["progress-circle"];
      const lineClassNames = ["progress-line"];
      const outDivClassNames = ["d-flex align-items-center"];
      // Hide left most line
      if (i === 1) {
        lineClassNames.push("d-none");
      } else {
        // First div does not grow to keep left aligned
        outDivClassNames.push("d-flex flex-grow-1");
      }
      // Contextual styling based on prop
      if (i > props.progress) {
        lineClassNames.push("dark-grey-border grey-progress-line");
        // White fill implicitly hides checkmark
        circleClassNames.push("bg-white dark-grey-border");
      }
      result.push(
        <div key={i} className={outDivClassNames.join(" ")}>
          <div className={lineClassNames.join(" ")} />
          <div className={circleClassNames.join(" ")}>
            <FontAwesomeIcon icon={solid("check")} size="xs" inverse />
          </div>
        </div>
      );
    }
    return result;
  };

  return <div className="progress-box">{renderWizardCircles()}</div>;
}

// Single prop is the number of circles you want filled
ProgressWizard.propTypes = {
  progress: PropTypes.number,
};
