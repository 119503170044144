import { useState, useEffect } from "react";
import { PATHS } from "../utils/constants";
import useMapValueDefinition from "../hooks/useMapValueDefinition";
import { ROLES } from "../utils/constants";

export default () => {
  const [selectedMapId, setSelectedMapId] = useState("");
  const [submissionFinished, setSubmissionFinished] = useState(false);
  const [submissionErrors, setSubmissionErrors] = useState([]);
  const [submissionHeader, setSubmissionHeader] = useState("");
  const [spawnSubmissionPopup, setSpawnSubmissionPopup] = useState(false);
  const [submissionSubText, setSubmissionSubText] = useState("");
  const [submissionCallback, setSubmissionCallback] = useState(() => () => {});
  const { mapStatus } = useMapValueDefinition();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [publishButtonDisabled, setPublishButtonDisabled] = useState(true);
  const [submitForReviewDisabled, setSubmitForReviewDisabled] = useState(true);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);

  const userRoles = window.sessionStorage.getItem("userRoles")?.split(",");

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      resetButtonOnMapStatus(mapStatus);
    }
    return () => (mounted = false);
  }, [mapStatus]);

  async function handleErrorResponse(response, setSubmissionErrors) {
    if (response.status === 403) {
      window.location.replace(PATHS.forbidden.url);
    }
    // Individual API error handlers
    if (response.status === 404) {
      setSubmissionErrors(["Map not found."]);
      return;
    }
    if (response.status === 422) {
      const json = await response.json();
      const errors = [];
      if (json.error) {
        errors.push(json.error);
      } else {
        json.records.forEach((record) => {
          record.errors.forEach((error) => {
            errors.push(error);
          });
        });
      }
      setSubmissionErrors(errors);
    }
  }

  const resetButtonOnMapStatus = (mapState) => {
    setSaveButtonDisabled(true);
    setPublishButtonDisabled(
      userRoles?.indexOf(ROLES.ROLE_ADMIN) < 0 || mapState !== "REVIEW"
    );
    setSubmitForReviewDisabled(mapState !== "DRAFT");
    setAddButtonDisabled(mapState === "REVIEW");
  };

  return {
    state: {
      submissionFinished,
      setSubmissionFinished,
      submissionErrors,
      setSubmissionErrors,
      submissionHeader,
      setSubmissionHeader,
      spawnSubmissionPopup,
      setSpawnSubmissionPopup,
      submissionSubText,
      setSubmissionSubText,
      submissionCallback,
      setSubmissionCallback,
      selectedMapId,
      setSelectedMapId,
      saveButtonDisabled,
      setSaveButtonDisabled,
      publishButtonDisabled,
      submitForReviewDisabled,
      setSubmitForReviewDisabled,
      addButtonDisabled,
    },
    handleErrorResponse,
    resetButtonOnMapStatus,
  };
};
