import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
import { AuthLoading } from "./authLoading";

export const Logout = () => (
  <AuthConsumer>
    {({ logout }) => {
      logout();
      return <AuthLoading loadingText="Logging Out..."></AuthLoading>;
    }}
  </AuthConsumer>
);
