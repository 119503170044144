import React from "react";
import { CmsHeader } from "../navigation/CmsHeader";

export const LoggedOut = () => (
  <div>
    <CmsHeader globalNav={false}></CmsHeader>
    <div className="logged-out">
      <h1>Logged Out</h1>
    </div>
  </div>
);
