import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import HeaderService from "../services/headerService";
import { BASE_URL } from "../utils/constants";

export const API = () => {
  const headerService = new HeaderService();
  const requestInterceptor = (req) => ({
    ...req,
    headers: headerService.getAuthorizationHeader(),
  });
  return (
    <SwaggerUI
      url={`${BASE_URL}/swagger`}
      requestInterceptor={requestInterceptor}
    />
  );
};
