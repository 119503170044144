import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Header, anchorProperties, getNativeProps } from "@uitk/react";
import Logo from "../../assets/logos/LogoWhiteText.svg";
import ProductName from "../../assets/logos/ProductName.svg";
import LogoWithProductName from "../../assets/logos/LogoWithProductName.svg";

const logo = (
  <>
    <img
      src={Logo}
      className="uitk-header__logo uitk-header__logo--small"
      alt="logo"
    />
    <img
      src={ProductName}
      className="uitk-header__logo uitk-header__logo--small uitk-header__product-name"
      alt="product name"
    />
    <img
      src={LogoWithProductName}
      className="uitk-header__logo uitk-header__logo--medium uitk-header__logo--large"
      alt="logo with product name"
    />
  </>
);

// define our custom link so client side routing works
const RoutableLink = (item) => {
  const { children, url } = item;
  const anchorProps = getNativeProps(item, anchorProperties);
  return (
    <Link to={url} {...anchorProps}>
      {children}
    </Link>
  );
};

const globalNavigationConfig = {
  linkAs: RoutableLink,
  links: [{ label: "Logout", url: "/logout" }],
};

export function CmsHeader(props) {
  if (props.globalNav) {
    return (
      <Header logoContent={logo} globalNavigation={globalNavigationConfig} />
    );
  }

  return <Header logoContent={logo} />;
}

CmsHeader.propTypes = {
  globalNav: PropTypes.bool,
};

CmsHeader.defaultProps = {
  globalNav: true,
};
