import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
import { AuthLoading } from "./authLoading";

export const LogoutCallback = () => (
  <AuthConsumer>
    {({ signoutRedirectCallback }) => {
      signoutRedirectCallback();
      return <AuthLoading></AuthLoading>;
    }}
  </AuthConsumer>
);
