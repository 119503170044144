// useNewsfeed.js
import { useState, useEffect, useRef } from "react";
import { BASE_URL, PATHS } from "../utils/constants";
import { merge } from "../utils/funcs";
import HeaderService from "../services/headerService";

export default () => {
  const [ontologyData, setOntologyData] = useState({});
  const [attrFilters, setAttrFilters] = useState(null);
  const [attrConfig, setAttrConfig] = useState(null);
  const [mapStatus, setMapStatus] = useState(null);
  const [mapVersion, setMapVersion] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [mapAttributes, setMapAttributes] = useState({});
  const [tableLoading, setTableLoading] = useState(true);
  const [attrLoading, setAttrLoading] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [isSearchBtnDisabled, setIsSearchBtnDisabled] = useState(true);
  const headerService = new HeaderService();
  const mountedRef = useRef(true);
  const [mapID, setMapID] = useState(null);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (attrConfig) {
      const root = attrConfig.attributes.configs.filter(
        (attr) => attr.root === true
      );

      if (!root[0].values) {
        // get root values
        fetchAttribute(attrConfig._id, root[0].name, {});
      }
    }
  }, [attrConfig]);

  async function fetchMapById(mapID, callbackToResetActionButtons) {
    setTableLoading(true);

    // Fetch the map object
    const response = await fetch(`${BASE_URL}/maps/${mapID}`, {
      headers: headerService.getAuthorizationHeader(),
    });

    if (response.status === 200) {
      const data = await response.json();
      const ontologyData = await getOntologyData(
        data,
        headerService.getAuthorizationHeader()
      );

      // Update state
      if (!mountedRef.current) {
        return;
      }
      setOntologyData(ontologyData);
      setMapVersion(data.version);
      setMapStatus(data.status);
      setRowData(data.mapValueDefinitions.records || []);
      setMapID(data._id);

      // Format attribute objects into readable json
      const mapAttrs = {};
      data["attributes"].forEach((element) => {
        mapAttrs[element.name.replace(/\s/g, "")] = {
          value: element.values.join(", "),
          name: element.name,
          editable: !!element.editable, // need to test with multiple editable attributes
          updated: element.updated,
          allowClone: element.allowClone,
        };
      });
      setMapAttributes(mapAttrs);

      // Table loading finish
      setTableLoading(false);
      setIsSearchDisabled(false);
      callbackToResetActionButtons(data.status);
    }

    // Individual API error handlers
    if (response.status === 404) {
      setTableLoading(false);
      return;
    }

    if (response.status === 403) {
      window.location.replace(PATHS.forbidden.url);
    }
  }

  async function fetchAttrConfig() {
    const response = await fetch(
      `${BASE_URL}/configs/627214d0a267cd4ef44f9d40`,
      {
        method: "GET",
        headers: headerService.getAuthorizationHeader(),
      }
    );

    if (response.status === 403) {
      window.location.replace(PATHS.forbidden.url);
    }

    if (response.status === 200) {
      const data = await response.json();
      setAttrConfig(data);
    }
  }

  async function fetchAttribute(configId, attrName, filters) {
    const filterString = Object.keys(filters)
      .map((key) => key + ":" + filters[key])
      .join(",");

    // TODO: Loading filter state
    const response = await fetch(
      `${BASE_URL}/attributes?configID=${encodeURIComponent(
        configId
      )}&getAttributes=${encodeURIComponent(
        attrName
      )}&filters=${encodeURIComponent(filterString)}`,
      {
        method: "GET",
        headers: headerService.getAuthorizationHeader(),
      }
    );

    if (response.status === 403) {
      window.location.replace(PATHS.forbidden.url);
    }

    if (response.status === 200) {
      const data = await response.json();
      const newConfigs = { ...attrConfig };
      const configs = newConfigs.attributes.configs;
      merge(configs, data.attributes, "name");
      setAttrConfig(newConfigs);
      setFilters(configs);
    }
  }

  async function setFilters(configs) {
    const attrFilters = configs.map((obj, index) => {
      return {
        ...obj,
        _id: index,
        disabled: !obj.values,
        values: !obj.values
          ? []
          : obj.values.map((item, index) => {
              return {
                id: index,
                label: item,
                value: item,
              };
            }),
      };
    });
    setAttrFilters(attrFilters);
    setAttrLoading(false);
  }

  const resetFilters = (attrObj, searchValues, newSearchValues) => {
    const startIndex = attrObj._id + 1;
    if (attrObj.child && Object.keys(searchValues).length > startIndex) {
      const newConfigs = { ...attrConfig };
      const configs = newConfigs.attributes.configs;
      for (let i = startIndex; i < configs.length; i++) {
        configs[i].values = null;
        delete newSearchValues[configs[i].name];
      }
      setAttrConfig(newConfigs);
      setFilters(configs);
    }
  };

  // Finally, it exposes only what is required by the component
  return {
    state: {
      ontologyData,
      attrFilters,
      attrConfig,
      mapStatus,
      mapVersion,
      rowData,
      setRowData,
      mapAttributes,
      setMapAttributes,
      tableLoading,
      setTableLoading,
      attrLoading,
      isSearchDisabled,
      setIsSearchDisabled,
      isSearchBtnDisabled,
      setIsSearchBtnDisabled,
      mapID,
    },
    fetchMapById,
    fetchAttribute,
    fetchAttrConfig,
    setFilters,
    resetFilters,
    headerService,
  };
};

async function getOntologyData(data, headers) {
  // Fetch the ontology object(s)
  const ontologyData = {};
  for (const columnKey in data.mapValueDefinitions.columns) {
    const column = data.mapValueDefinitions.columns[columnKey];
    const ontologyId = column["ontologyId"];
    if (ontologyId) {
      const response = await fetch(`${BASE_URL}/ontologies/${ontologyId}`, {
        headers: headers,
      });

      if (response.status === 403) {
        window.location.replace(PATHS.forbidden.url);
      }

      if (response.status === 200) {
        const data = await response.json();
        // Map the response to the column key
        ontologyData[column.key] = data;
      }
    }
  }
  return ontologyData;
}
