export default class HeaderService {
  getAuthorizationHeader = () => {
    const accessToken = this.getAccessToken();
    const header = {
      Authorization: "Bearer " + accessToken,
    };
    return header;
  };

  getAccessToken = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
      )
    );
    return oidcStorage.access_token;
  };
}
