import PropTypes from "prop-types";
import { Button, TextInput } from "@uitk/react";
import { useState } from "react";

export function PaginationPanel(props) {
  const [entriesPerPage, setEntriesPerPage] = useState(25);
  const [cachedCurrentPage, setCachedCurrentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const updateCurrentPage = (updatedPageNumber) => {
    let page = Number(updatedPageNumber);
    if (page > 0) {
      page -= 1;
    }
    if (page === "" || page < 0) {
      page = 0;
    }
    const totalPages = props.gridApi.paginationGetTotalPages();
    if (page >= totalPages) {
      page = totalPages;
      setCurrentPage(page);
      setCachedCurrentPage(page);
    } else {
      setCurrentPage(page + 1);
      setCachedCurrentPage(page + 1);
    }
    props.gridApi.paginationGoToPage(page);
  };

  const onEntriesPerPageChanged = (event) => {
    props.gridApi.paginationSetPageSize(event.target.innerText);
    setEntriesPerPage(Number(event.target.innerText));
    updateCurrentPage(currentPage);
  };

  return (
    <div className="d-flex align-items-center flex-grow-1 p-3 bg-white">
      <div className="d-flex justify-content-start align-items-center">
        <strong>Entries per page:</strong>
        <div className="d-flex ms-2">
          <Button
            className="me-2"
            variant={entriesPerPage === 25 ? "" : "ghost"}
            onPress={onEntriesPerPageChanged}
          >
            25
          </Button>
          <Button
            className="me-2"
            variant={entriesPerPage === 50 ? "" : "ghost"}
            onPress={onEntriesPerPageChanged}
          >
            50
          </Button>
          <Button
            variant={entriesPerPage === 100 ? "" : "ghost"}
            onPress={onEntriesPerPageChanged}
          >
            100
          </Button>
        </div>
      </div>
      <div className="d-flex justify-content-end flex-grow-1 align-items-center">
        <span>
          Displaying Page: {cachedCurrentPage} of{" "}
          {props.gridApi ? props.gridApi.paginationGetTotalPages() : ""}
        </span>
        <span className="mx-2">|</span>
        <span className="d-flex align-items-center text-nowrap">
          <span className="me-2">
            <b>Go to Page</b>
          </span>
          <TextInput
            variant="ghost"
            className="pagination-text mt-0 me-2"
            value={currentPage}
            onChange={(event) => {
              const val = event.target.value;
              // Returns true if it's a number
              if (!isNaN(+val)) {
                setCurrentPage(val);
              }
            }}
          ></TextInput>
          <Button
            variant="alternative"
            className="ms-2"
            onPress={() => {
              updateCurrentPage(currentPage);
            }}
          >
            Go
          </Button>
          <Button
            variant="ghost-alternative"
            className="ms-2"
            onPress={() => {
              updateCurrentPage(cachedCurrentPage - 1);
            }}
          >
            Previous
          </Button>
          <Button
            variant="ghost-alternative"
            className="ms-2"
            onPress={() => {
              updateCurrentPage(cachedCurrentPage + 1);
            }}
          >
            Next
          </Button>
        </span>
      </div>
    </div>
  );
}

PaginationPanel.propTypes = {
  gridApi: PropTypes.object,
};
