import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function EditableAttribute(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [attrKey, setAttrKey] = useState("");
  const [attrName, setAttrName] = useState("");
  const [attrVal, setAttrVal] = useState("");

  const editAttr = (key, value) => {
    setAttrVal(value);
  };

  const cancelEdit = () => {
    setAttrVal(props.attrVal);
    setIsEditing(false);
  };

  const confirmEdit = (attrKey, attrName, attrVal) => {
    if (attrVal !== props.attrVal) {
      props.saveEdit(attrKey, attrName, attrVal.trim());
    }
    setIsEditing(false);
  };

  useEffect(() => {
    setAttrKey(props.attrKey);
    setAttrName(props.attrName);
    setAttrVal(props.attrVal);
  }, []);

  return (
    <div>
      {isEditing ? (
        <div className="input-group">
          <label
            className="d-none"
            htmlFor={`editAttr-${attrName}`}
          >{`Edit ${attrName} Attribute`}</label>
          <input
            id={`editAttr-${attrName}`}
            className="form-control form-control-sm"
            type="text"
            value={attrVal}
            maxLength="30"
            onChange={(event) => {
              editAttr(attrKey, event.target.value);
            }}
          />
          <button
            title="Confirm Edit Attribute"
            className="btn btn-outline-secondary"
            onClick={() => confirmEdit(attrKey, attrName, attrVal)}
          >
            <FontAwesomeIcon icon={solid("check")} />
          </button>
          <button
            title="Cancel Edit Attribute"
            className="btn btn-outline-secondary"
            onClick={() => cancelEdit()}
          >
            <FontAwesomeIcon icon={solid("xmark")} />
          </button>
        </div>
      ) : (
        <div>
          <span className="me-2">{attrVal}</span>
          <FontAwesomeIcon
            title="Edit Attribute"
            onClick={() => setIsEditing(true)}
            icon={solid("square-pen")}
            size="lg"
            className="icon-link"
          />
        </div>
      )}
    </div>
  );
}

EditableAttribute.propTypes = {
  attrKey: PropTypes.string.isRequired,
  attrName: PropTypes.string.isRequired,
  attrVal: PropTypes.string.isRequired,
  saveEdit: PropTypes.func.isRequired,
};
