import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

export default function AttributeFilter(props) {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleFilterChange = (event) => {
    setSelectedItem(event);
    props.onFilterChange(event, props.filter);
  };

  useEffect(() => {
    if (!props.filter.values.length) {
      setSelectedItem(null);
    }
  });

  return (
    <div className="col">
      <label htmlFor={props.filter.name} className="fw-bold">
        {props.filter.name}
      </label>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isDisabled={props.isSearchDisabled || props.filter.disabled}
        isSearchable={true}
        value={selectedItem}
        name={props.filter.name}
        onChange={(event) => handleFilterChange(event)}
        options={props.filter.values}
        id={props.filter.name}
        aria-label={props.filter.name}
      />
    </div>
  );
}

AttributeFilter.propTypes = {
  isSearchDisabled: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
