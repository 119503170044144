import React from "react";
import { CmsHeader } from "../navigation/CmsHeader";

export const Forbidden = () => (
  <div>
    <CmsHeader globalNav={false}></CmsHeader>
    <div className="logged-out">
      <h1>403 Forbidden</h1>
      <p>You do not have permission to access this page or resource.</p>
    </div>
  </div>
);
