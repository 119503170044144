import PropTypes from "prop-types";
import { Dialog } from "@uitk/react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { CustomHeader } from "./CustomHeader";

export function OntologyPopup(props) {
  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      title="Ontology Definition"
      titleAs="h2"
      onClose={props.onClose}
    >
      <Dialog.Body>
        <div className="mb-3">
          <strong>Name:</strong> {props.ontologyPopupData.name}
        </div>
        <div
          data-testid="ontologyGrid"
          className="ontology-table ag-theme-alpine table-comp table-wrap"
        >
          <AgGridReact
            domLayout="autoHeight"
            rowData={props.ontologyPopupData.values}
            frameworkComponents={{
              agColumnHeader: CustomHeader,
            }}
            rowClass="ag-grid-custom-row-class"
            defaultColDef={{
              sortable: true,
              filter: false,
              resizable: false,
            }}
            pagination={true}
            paginationPageSize={25}
            onFirstDataRendered={onFirstDataRendered}
          >
            <AgGridColumn
              field={"code"}
              headerComponentParams={{
                primaryName: "Code",
              }}
            />
            <AgGridColumn
              field={"description"}
              headerComponentParams={{
                primaryName: "Description",
              }}
            />
          </AgGridReact>
        </div>
      </Dialog.Body>
    </Dialog>
  );
}

OntologyPopup.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  ontologyPopupData: PropTypes.object,
};
