import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "@uitk/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PropTypes from "prop-types";

// See https://www.ag-grid.com/react-data-grid/component-header/
export function CustomHeader(props) {
  const refButton = useRef(null);
  const [sortState, setSortState] = useState("");

  const onMenuClicked = (event) => {
    // Stop the event otherwise it triggers sort
    event.stopPropagation();
    props.showColumnMenu(refButton.current);
  };

  const getSortClass = () => {
    if (sortState === "desc") {
      return "customSortDownLabel";
    }
    if (sortState === "asc") {
      return "customSortUpLabel";
    }
    return "";
  };

  const progressSort = () => {
    props.progressSort();
    sortChanged();
  };

  const sortChanged = () => {
    setSortState(props.column.sort);
  };

  useEffect(() => {
    if (props.enableSorting) {
      // Sort when the user clicks the header
      setSortState(props.column.sort);
      props.eGridHeader.addEventListener("click", progressSort);

      // When sort changes globally, update the state
      props.column.addEventListener("sortChanged", sortChanged);
    }

    return () => {
      if (props.enableSorting) {
        // Clear event listeners on-destroy
        props.eGridHeader.removeEventListener("click", progressSort);
        props.column.removeEventListener("sortChanged", sortChanged);
      }
    };
  }, []);

  return (
    <div className="d-flex">
      <div className="d-flex flex-column me-2">
        <div className="custom-header-h1">{props.primaryName}</div>
        <div className="custom-header-h2">{props.secondaryName}</div>
      </div>
      <div className="d-flex flex-grow-1 align-items-center">
        {props.ontologyProps && (
          <div className="tooltipWrap">
            <Tooltip
              tooltipParentSelector={() => {
                return document.querySelector("#mvdGrid");
              }}
              content={`${props.ontologyProps.name}`}
            >
              <FontAwesomeIcon
                title="ontologyPopup"
                onClick={() => props.ontologyHandler(props.ontologyProps.id)}
                icon={solid("circle-info")}
                className="icon-link"
                size="lg"
              />
            </Tooltip>
          </div>
        )}
        {props.enableSorting && (
          <div className="d-flex flex-column">
            <span className={getSortClass()} />
          </div>
        )}
        {props.enableMenu && (
          <div className="d-flex flex-column customHeaderMenuIconWrap">
            <span
              ref={refButton}
              className="customHeaderMenuIcon"
              onClick={(event) => onMenuClicked(event)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

CustomHeader.propTypes = {
  column: PropTypes.object,
  eGridHeader: PropTypes.object,
  showColumnMenu: PropTypes.func,
  progressSort: PropTypes.func,
  enableMenu: PropTypes.bool,
  enableSorting: PropTypes.bool,
  primaryName: PropTypes.string,
  secondaryName: PropTypes.string,
  ontologyProps: PropTypes.object,
  ontologyHandler: PropTypes.func,
};
