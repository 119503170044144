import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  Label,
  TextInput,
  HelperText,
  Dialog,
} from "@uitk/react";

export default function AddAttributeForm(props) {
  const [attrName, setAttrName] = useState("");
  const [attrVal, setAttrVal] = useState("");
  const defaultHelperText = "* This field is required.";
  const blankHelperText = "* This field cannot be blank.";
  const [attrValHelperText, setAttrValHelperText] = useState(defaultHelperText);

  useEffect(() => {
    setAttrName(props.attrName);
    setAttrVal("");
  }, []);

  const attributeValueIsEmpty = () => {
    if (attrVal === "") {
      setAttrValHelperText(blankHelperText);
      return true;
    }
  };

  const saveAttribute = () => {
    if (attributeValueIsEmpty()) {
      return;
    }
    props.addAttrCallback(attrVal);
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      title={`Add New ${attrName}`}
      titleAs="h2"
      onClose={props.onClose}
    >
      <Dialog.Body className="px-1">
        {" "}
        <FormControl id={"attrVal"} className="mb-3">
          <Label>Enter value for {attrName} :</Label>
          <HelperText className="text-danger">{attrValHelperText}</HelperText>
          <TextInput
            value={attrVal}
            onChange={(event) => {
              setAttrVal(event.target.value);
            }}
          />
        </FormControl>
      </Dialog.Body>
      <Dialog.Actions>
        {" "}
        <Button
          onPress={() => {
            saveAttribute();
          }}
        >
          Save
        </Button>
        <Button variant="ghost" onPress={props.onClose}>
          Cancel
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}

AddAttributeForm.propTypes = {
  attrName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  addAttrCallback: PropTypes.func.isRequired,
};
