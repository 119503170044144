export const BASE_URL_LOCAL = "http://127.0.0.1:3000";
export const BASE_URL = process.env.REACT_APP_API_HOST.substring(
  0,
  process.env.REACT_APP_API_HOST.length - 1
);

// Use this BASE_URL to access API without local backend setup
// export const BASE_URL =
//   "https://x3da7xh7bk.execute-api.us-east-1.amazonaws.com";

export const MAP_ID = "0693f47f-5510-4cee-953a-54ddf448da19";

export const PATHS = {
  forbidden: {
    url: "/forbidden",
  },
  mapManagement: {
    displayName: "Map Management",
    url: "/map-management",
  },
  editMap: {
    displayName: "Edit Map",
    url: "/map-management/edit-map",
  },
  editMapMapValueDefinition: {
    displayName: "Map Value Definition",
    url: "/map-management/edit-map/map-value-definition",
  },
};

export const ROLES = {
  ROLE_ADMIN: "ADMIN",
  ROLE_DATA_ANALYST: "DATA_ANALYST",
};
