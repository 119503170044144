import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import EditableAttribute from "./EditableAttribute";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function MapAttributes(props) {
  const [attrs, setAttrs] = useState({});

  useEffect(() => {
    setAttrs(props.attributes);
  });

  return (
    <div className="d-flex flex-grow-1 justify-content-evenly selected-attributes-box">
      {Object.keys(attrs).map((key, index) => {
        const obj = attrs[key];

        return (
          <div key={index} className="d-flex justify-content-center">
            <div className="d-flex align-items-center flex-column mt-2">
              <div className="attr-name">
                {obj.name}{" "}
                {obj.changed ? (
                  <div className="badge bg-warning">Changed</div>
                ) : (
                  ""
                )}
                {obj.updated && !obj.changed ? (
                  <div className="badge bg-success">Updated</div>
                ) : (
                  ""
                )}
                {obj.allowClone ? (
                  <FontAwesomeIcon
                    title={"Add " + obj.name}
                    onClick={() => {
                      props.addAttrOnPress(obj.name);
                    }}
                    icon={solid("square-plus")}
                    size="lg"
                    className="icon-link"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex align-items-center my-2">
                {obj.editable && props.mapStatus !== "REVIEW" ? (
                  <EditableAttribute
                    attrKey={key}
                    attrName={obj.name}
                    attrVal={obj.value}
                    saveEdit={props.editAttr}
                  ></EditableAttribute>
                ) : (
                  obj.value
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

MapAttributes.propTypes = {
  mapStatus: PropTypes.string.isRequired,
  editAttr: PropTypes.func.isRequired,
  attributes: PropTypes.object.isRequired,
  addAttrOnPress: PropTypes.func,
};
