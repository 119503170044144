import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { Callback } from "../components/auth/callback";
import { Logout } from "../components/auth/logout";
import { LogoutCallback } from "../components/auth/logoutCallback";
import { PrivateRoute } from "./privateRoute";
import { SilentRenew } from "../components/auth/silentRenew";
import { HomePage } from "../pages/HomePage";
import { MapManagment } from "../pages/MapManagement";
import MapValueDefinition from "../pages/MapValueDefinition";
import { LoggedOut } from "../components/auth/loggedOut";
import { Forbidden } from "../components/auth/forbidden";
import { API } from "../pages/Api";
import { PATHS } from "../utils/constants";

export const Routes = (
  <Switch>
    <Route exact path="/callback" component={Callback} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/logout/callback" component={LogoutCallback} />
    <Route exact path="/silentrenew" component={SilentRenew} />
    <Route exact path="/loggedout" component={LoggedOut} />
    <Route exact path={PATHS.forbidden.url} component={Forbidden} />
    <PrivateRoute exact path="/" component={HomePage} />
    <PrivateRoute
      exact
      path={PATHS.mapManagement.url}
      component={MapManagment}
    />
    <PrivateRoute
      exact
      path={PATHS.editMapMapValueDefinition.url}
      component={MapValueDefinition}
    />
    <PrivateRoute exact path="/api" component={API} />
  </Switch>
);
