import { CmsHeader } from "../navigation/CmsHeader";
import { CmsFooter } from "../navigation/CmsFooter";
import PropTypes from "prop-types";

// Default header and footer
const Layout = ({ children }) => {
  return (
    <>
      <CmsHeader />
      <main>{children}</main>
      <CmsFooter />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Layout;
