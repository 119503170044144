/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { AuthConsumer } from "../providers/authProvider";
import { AuthLoading } from "../components/auth/authLoading";

export const PrivateRoute = ({ component, ...rest }) => {
  const renderFn = (Component) => (props) =>
    (
      <AuthConsumer>
        {({ isAuthenticated, signinRedirect }) => {
          if (!!Component && isAuthenticated()) {
            return <Component {...props} />;
          } else {
            signinRedirect();
            return <AuthLoading></AuthLoading>;
          }
        }}
      </AuthConsumer>
    );

  return <Route {...rest} render={renderFn(component)} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
};
