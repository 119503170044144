import { IDENTITY_CONFIG, METADATA_OIDC } from "../utils/authConfig";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { BASE_URL, PATHS } from "../utils/constants";
import HeaderService from "../services/headerService";

export default class AuthService {
  UserManager;
  headerService;

  constructor() {
    this.headerService = new HeaderService();
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;
    this.UserManager.events.addUserLoaded(() => {
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });
    this.UserManager.events.addAccessTokenExpired(() => {
      alert("Your session has expired! Kindly login to continue!!");
      sessionStorage.removeItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
      );
      this.signinRedirect();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(async () => {
      if (process.env.REACT_APP_DEVELOPMENT_AUTH_DISABLED === "true") {
        sessionStorage.setItem("userRoles", ["ADMIN"]);
      } else {
        await this.addUserProfileToSession();
      }
      window.location.replace(PATHS.editMapMapValueDefinition.url);
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    window.location.replace("/en/dashboard");
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
      )
    );
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log("signed in", user);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    sessionStorage.removeItem(
      `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
    );
    this.signoutRedirectCallback();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_LOGOFF_REDIRECT_URL);
    });
    this.UserManager.clearStaleState();
  };

  addUserProfileToSession = async () => {
    const response = await fetch(`${BASE_URL}/userprofile`, {
      method: "GET",
      headers: await this.headerService.getAuthorizationHeader(),
    });
    if (response.status === 200) {
      const data = await response.json();
      sessionStorage.setItem("userRoles", data.roles);
    }
    if (response.status === 403) {
      window.location.replace(PATHS.forbidden.url);
    }
  };
}
