import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  Label,
  TextInput,
  HelperText,
  Dropdown,
  Dialog,
  Notification,
} from "@uitk/react";
import { useState, useEffect } from "react";

export default function AddEditRowForm(props) {
  const edit = "Edit";
  const add = "Add";
  const defaultHelperText = "* This field is required.";
  const blankHelperText = "* This field cannot be blank.";
  const alreadyExistsHelperText = "* Field already exists in the table.";

  const [isError, setIsError] = useState(false);
  const [id, setId] = useState("");
  const [localCode, setLocalCode] = useState("");
  const [localName, setLocalName] = useState("");
  const [outputDesc, setOutputDesc] = useState("");
  const [gender, setGender] = useState("");
  const [genderDropdownValues, setGenderDropdownValues] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [context, setContext] = useState("");
  const [comments, setComments] = useState("");
  const [status, setStatus] = useState("");
  const [selectedStatusItem, setSelectedStatusItem] = useState({});
  const [statusDropdownValues, setStatusDropdownValues] = useState([]);
  const [localCodeIsError, setLocalCodeIsError] = useState(false);
  const [localCodeHelperText, setLocalCodeHelperText] =
    useState(defaultHelperText);
  const [formMode, setFormMode] = useState(add);
  const [genErrorMsg, setGenErrorMsg] = useState(
    "Please correct the error(s) below."
  );

  const [addRowId, setAddRowId] = useState("");

  const localCodeIsEmpty = () => {
    if (localCode === "") {
      setIsError(true);
      setLocalCodeIsError(true);
      setLocalCodeHelperText(blankHelperText);
      return true;
    }
    return false;
  };

  const localCodeIsAlreadyInTable = () => {
    if (props.records === undefined) {
      return false;
    }
    // Local code cannot already exist in the table
    for (const record of props.records) {
      //Adding a check to skip uniqueness check on records which are deleted either in working copy or draft state
      if (
        !(record.flags && record.flags.deleted === true) ||
        record.delete === true
      ) {
        if (record.inputCode1.toLowerCase() === localCode.toLowerCase()) {
          setIsError(true);
          setLocalCodeIsError(true);
          setLocalCodeHelperText(alreadyExistsHelperText);
          return true;
        }
      }
    }
    return false;
  };

  const validateAddRowTable = () => {
    if (localCodeIsEmpty()) {
      return;
    }

    // For new rows, the local code can't exist in the table
    if (formMode === add) {
      if (props.formData.inputCode1 !== localCode) {
        if (localCodeIsAlreadyInTable()) {
          return;
        }
      }
    }

    // For edited rows, the local code can exist in the table so long as it's unchanged
    if (formMode === edit) {
      // If the local code has changed & is already in the table, exit
      if (props.formData.inputCode1 !== localCode) {
        if (localCodeIsAlreadyInTable()) {
          return;
        }
      }
      // If the local code has not changed, something else needs to have changed
      else {
        if (
          localName === props.formData.inputInfo1 &&
          outputDesc === props.formData.outputInfo1 &&
          context === props.formData.context &&
          comments === props.formData.comments &&
          gender === props.formData.outputCode1 &&
          status === props.formData.status
        ) {
          setGenErrorMsg("No fields have been edited.");
          setIsError(true);
          return;
        }
      }
    }

    // Fall through assumes validation passed
    setIsError(false);
    setLocalCodeIsError(false);
    setLocalCodeHelperText(defaultHelperText);

    props.formCallback({
      inputCode1: localCode,
      inputInfo1: localName,
      outputInfo1: outputDesc,
      outputCode1: gender,
      context: context,
      status: status,
      comments: comments,
      lastEditedAt: props.formData.lastEditedAt,
      lastEditedBy: props.formData.lastEditedBy,
      _id: id,
      addRowId: addRowId,
    });
  };

  const saveRow = () => {
    validateAddRowTable();
  };

  const setDropdown = (event) => {
    setSelectedItem(event);
    setGender(event.value);
    setOutputDesc(event.description);
  };

  const setStatusDropdown = (event) => {
    setSelectedStatusItem(event);
    setStatus(event.value);
  };

  useEffect(() => {
    // If edit mode form, bind the prop to the form values
    if (props.formData._id) {
      setFormMode(edit);
    }
    setId(props.formData._id);
    setLocalCode(props.formData.inputCode1);
    setLocalName(props.formData.inputInfo1);
    setOutputDesc(props.formData.outputInfo1);
    setGender(props.formData.outputCode1);
    setContext(props.formData.context);
    setComments(props.formData.comments);
    setAddRowId(props.formData.addRowId);
    const dropdownValues = [{ id: -1, label: " ", value: "" }];
    setSelectedItem(dropdownValues[0]);
    props.formData.validOntologies.forEach((element, index) => {
      const obj = {
        id: index + 1,
        label: `${element.description} (${element.code})`,
        value: element.code,
        description: element.description,
      };
      if (element.code === props.formData.outputCode1) {
        setSelectedItem(obj);
      }
      dropdownValues.push(obj);
    });
    setGenderDropdownValues(dropdownValues);
    const statusDropdownValues = [
      { id: -1, label: "NEEDS_REVIEW", value: "NEEDS_REVIEW" },
      { id: 0, label: "TENTATIVE", value: "TENTATIVE" },
      { id: 1, label: "WONT_MAP", value: "WONT_MAP" },
      { id: 2, label: "MAPPED", value: "MAPPED" },
    ];
    setStatusDropdownValues(statusDropdownValues);
    const statusDropdownValue =
      statusDropdownValues.filter(function (item) {
        return item.value == props.formData.status;
      })[0] || statusDropdownValues[0];
    setSelectedStatusItem(statusDropdownValue);
    setStatus(statusDropdownValue.value);
  }, []);

  return (
    <Dialog
      isOpen={props.isOpen}
      title={`${formMode} Row`}
      titleAs="h2"
      onClose={props.onClose}
    >
      <Dialog.Body className="px-1">
        {isError && (
          <Notification
            className="mb-3"
            variant={"error"}
            id={"notification-error"}
          >
            {genErrorMsg}
          </Notification>
        )}{" "}
        <FormControl id={"localCode"} className="mb-3" error={localCodeIsError}>
          <Label>Local Code</Label>
          <HelperText className="text-danger">{localCodeHelperText}</HelperText>
          <TextInput
            value={localCode}
            onChange={(event) => {
              setLocalCode(event.target.value);
            }}
            error={localCodeIsError}
          />
        </FormControl>
        <FormControl id={"localName"} className="mb-3">
          <Label>Local Name</Label>
          <HelperText className="text-danger"></HelperText>
          <TextInput
            value={localName}
            onChange={(event) => {
              setLocalName(event.target.value);
            }}
          />
        </FormControl>
        <FormControl data-testid="dropdown" id={"gender"} className="mb-3">
          <Label>Gender</Label>
          <Dropdown
            type="single"
            value={selectedItem}
            items={genderDropdownValues}
            onChange={setDropdown}
          />
        </FormControl>
        <FormControl id={"outputDesc"} className="mb-3">
          <Label>Output Description</Label>
          <HelperText className="text-danger"></HelperText>
          <TextInput
            value={outputDesc}
            onChange={(event) => {
              setOutputDesc(event.target.value);
            }}
          />
        </FormControl>
        <FormControl data-testid="dropdown" id={"status"} className="mb-3">
          <Label>Status</Label>
          <Dropdown
            type="single"
            value={selectedStatusItem}
            items={statusDropdownValues}
            onChange={setStatusDropdown}
          />
        </FormControl>
        <FormControl id={"context"} className="mb-3">
          <Label>Context</Label>
          <TextInput
            value={context}
            onChange={(event) => {
              setContext(event.target.value);
            }}
          />
        </FormControl>
        <FormControl id={"comments"} className="mb-3">
          <Label>Comments</Label>
          <TextInput
            value={comments}
            onChange={(event) => {
              setComments(event.target.value);
            }}
          />
        </FormControl>
      </Dialog.Body>
      <Dialog.Actions>
        {" "}
        <Button onPress={saveRow}>Save</Button>
        <Button variant="ghost" onPress={props.onClose}>
          Cancel
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}

AddEditRowForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  records: PropTypes.array,
  formCallback: PropTypes.func,
  formData: PropTypes.object,
};
