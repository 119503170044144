import PropTypes from "prop-types";
import { Button, Dialog } from "@uitk/react";
import { useState, useEffect } from "react";

export default function DeleteRowForm(props) {
  const [id, setId] = useState("");
  const [localCode, setLocalCode] = useState("");
  const [localName, setLocalName] = useState("");
  const [outputDesc, setOutputDesc] = useState("");
  const [gender, setGender] = useState("");
  const [context, setContext] = useState("");
  const [comments, setComments] = useState("");
  const [status, setStatus] = useState("");
  const [lastEditedAt, setLastEditedAt] = useState("");
  const [lastEditedBy, setLastEditedBy] = useState("");

  const deleteRow = () => {
    props.deleteCallback({
      status: status,
      delete: true,
      _id: id,
      inputCode1: localCode,
      inputInfo1: localName,
      outputInfo1: outputDesc,
      outputCode1: gender,
      context: context,
      comments: comments,
      lastEditedAt: lastEditedAt,
      lastEditedBy: lastEditedBy,
    });
  };

  useEffect(() => {
    setId(props.formData._id);
    setLocalCode(props.formData.inputCode1);
    setLocalName(props.formData.inputInfo1);
    setOutputDesc(props.formData.outputInfo1);
    setGender(props.formData.outputCode1);
    setContext(props.formData.context);
    setComments(props.formData.comments);
    setStatus(props.formData.status);
    setLastEditedAt(props.formData.lastEditedAt);
    setLastEditedBy(props.formData.lastEditedBy);
  }, []);
  return (
    <Dialog
      isOpen={props.isOpen}
      title="Delete Row"
      titleAs="h2"
      onClose={props.onClose}
    >
      <Dialog.Body>Are you sure want to Delete?</Dialog.Body>
      <Dialog.Actions>
        <Button onPress={deleteRow}>Yes</Button>
        <Button onPress={props.onClose}>Cancel</Button>
      </Dialog.Actions>
    </Dialog>
  );
}

DeleteRowForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  deleteCallback: PropTypes.func,
  formData: PropTypes.object,
};
