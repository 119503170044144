import React from "react";
import PropTypes from "prop-types";
import { LoadingIndicator } from "@uitk/react";
import { CmsHeader } from "../navigation/CmsHeader";

export const AuthLoading = (props) => (
  <div>
    <CmsHeader globalNav={false}></CmsHeader>
    <div className="auth-loading">
      <LoadingIndicator
        size={"l"}
        loading={true}
        centerSpinner={true}
        loadingText={props.loadingText}
      />
    </div>
  </div>
);

AuthLoading.propTypes = {
  loadingText: PropTypes.string,
};

AuthLoading.defaultProps = {
  loadingText: "Authenticating...",
};
