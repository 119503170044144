import React, { Component } from "react";
import PropTypes from "prop-types";
import AuthService from "../services/authService";

const AuthContext = React.createContext({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => ({}),
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
});

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
  authService;
  constructor(props) {
    super(props);
    this.authService = new AuthService();
  }
  render() {
    if (this.props.enabled) {
      return (
        <AuthContext.Provider value={this.authService}>
          {this.props.children}
        </AuthContext.Provider>
      );
    }
    return <>{this.props.children}</>;
  }
}

AuthProvider.propTypes = {
  children: PropTypes.node,
  enabled: PropTypes.bool,
};
