import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
import { AuthLoading } from "./authLoading";

export const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback();
      return <AuthLoading></AuthLoading>;
    }}
  </AuthConsumer>
);
