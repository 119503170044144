import React from "react";
import { Toolkit } from "@uitk/react";
import { AuthProvider } from "./providers/authProvider";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./routes/routes";

function App() {
  const appId = "COMMON_MAPPING_SOLUTION";

  // remove enabled prop to run without auth
  return (
    <Toolkit app={appId} grid>
      <AuthProvider enabled>
        <Router basename="/">{Routes}</Router>
      </AuthProvider>
    </Toolkit>
  );
}

export default App;
