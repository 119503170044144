import Layout from "../components/layout/Layout";
import { Card, Text } from "@uitk/react";
import { PATHS } from "../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export function HomePage() {
  return (
    <Layout>
      <div className="grid">
        <div className="row">
          <div className="col">
            <h2>Common Mapping Solution</h2>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet. Proin gravida dolor sit amet lacus
              accumsan et viverra justo commodo. Proin sodales pulvinar tempor.
              Cum sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget
              odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Aenean euismod bibendum laoreet.
            </Text>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <a href={PATHS.mapManagement.url} className="text-decoration-none">
              <Card
                subheader={<h2 className="flex-grow-1">Map Management</h2>}
                className="text-center"
                media={{
                  img: (
                    <div className="mt-4 card-icon">
                      <FontAwesomeIcon
                        icon={solid("network-wired")}
                        className="icon-link"
                      />
                    </div>
                  ),
                }}
              >
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                  lacus accumsan et viverra justo
                </Text>
              </Card>
            </a>
          </div>
          <div className="col">
            <a href="#" className="text-decoration-none">
              <Card
                subheader={<h2 className="flex-grow-1">Coding Updates</h2>}
                className="text-center"
                media={{
                  img: (
                    <div className="mt-4 card-icon">
                      <FontAwesomeIcon
                        icon={solid("server")}
                        className="icon-link"
                      />
                    </div>
                  ),
                }}
              >
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                  lacus accumsan et viverra justo
                </Text>
              </Card>
            </a>
          </div>
          <div className="col">
            <a href="#" className="text-decoration-none">
              <Card
                subheader={
                  <h2 className="flex-grow-1">Ontology & Standards</h2>
                }
                className="text-center"
                media={{
                  img: (
                    <div className="mt-4 card-icon">
                      <FontAwesomeIcon
                        icon={solid("book-open")}
                        className="icon-link"
                      />
                    </div>
                  ),
                }}
              >
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                  lacus accumsan et viverra justo
                </Text>
              </Card>
            </a>
          </div>
          <div className="col">
            <a href="#" className="text-decoration-none">
              <Card
                subheader={<h2 className="flex-grow-1">Administration</h2>}
                className="text-center"
                media={{
                  img: (
                    <div className="mt-4 card-icon">
                      <FontAwesomeIcon
                        icon={solid("gears")}
                        className="icon-link"
                      />
                    </div>
                  ),
                }}
              >
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                  lacus accumsan et viverra justo
                </Text>
              </Card>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
