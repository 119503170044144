import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
import { AuthLoading } from "./authLoading";

export const Callback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback();
      return <AuthLoading></AuthLoading>;
    }}
  </AuthConsumer>
);
