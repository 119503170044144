import { useRef } from "react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import PropTypes from "prop-types";
import ActionsMenuCellRenderer from "./ActionsMenuCellRenderer";
import { CustomHeader } from "./CustomHeader";
import { PaginationPanel } from "./PaginationPanel";
import moment from "moment";

export function Grid(props) {
  const gridRef = useRef();

  const onGridReady = (params) => {
    if (props.mapStatus === "REVIEW") {
      params.columnApi.setColumnVisible("actions", false);
    }

    // Allow auto-sizing on small grids
    if (props.rowData && props.rowData.length < 15) {
      params.api.setDomLayout("autoHeight");
      document.querySelector("#mvdGrid").style.height = "";
    } else {
      params.api.setDomLayout("normal");
      document.querySelector("#mvdGrid").style.height = "40rem";
    }

    params.api.setRowData(props.rowData || []);

    // Expose the grid api
    props.gridApi(params.api);
  };

  const gridOptions = {
    rowClassRules: {
      "row-deleted": (params) => {
        const flags = params.node.data.flags;
        return flags ? flags.deleted == true : params.node.data.delete;
      },
    },
    suppressCellSelection: true,
  };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const getRowNodeId = (data) => {
    return data._id != null ? data._id : data.addRowId;
  };

  const getOntologyTooltipData = (columnKey) => {
    const columnOntData = props.ontologyData[columnKey];
    if (columnOntData) {
      return {
        id: columnOntData._id,
        name: columnOntData.name,
      };
    } else {
      return null;
    }
  };

  return (
    <div
      id="mvdGrid"
      data-testid="agGrid"
      className="ag-theme-alpine table-comp table-wrap"
    >
      <AgGridReact
        ref={gridRef}
        frameworkComponents={{
          actionsMenuCellRenderer: ActionsMenuCellRenderer,
          agColumnHeader: CustomHeader,
        }}
        rowClass="ag-grid-custom-row-class"
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
        onGridReady={onGridReady}
        alwaysShowHorizontalScroll={true}
        pagination={true}
        suppressPaginationPanel={true}
        paginationPageSize={25}
        getRowNodeId={getRowNodeId}
        onFirstDataRendered={onFirstDataRendered}
        onGridSizeChanged={onGridSizeChanged}
        gridOptions={gridOptions}
      >
        <AgGridColumn
          minWidth={150}
          field="inputCode1"
          sortable={true}
          headerComponentParams={{
            primaryName: "Local Code",
            secondaryName: "Input Code",
            ontologyProps: getOntologyTooltipData("inputCode1"),
            ontologyHandler: props.ontologyHandler,
          }}
        />
        <AgGridColumn
          minWidth={150}
          field="inputInfo1"
          headerComponentParams={{
            primaryName: "Local Name",
            ontologyProps: getOntologyTooltipData("inputInfo1"),
            ontologyHandler: props.ontologyHandler,
          }}
        />
        <AgGridColumn
          minWidth={150}
          field="outputCode1"
          headerComponentParams={{
            primaryName: "Gender",
            secondaryName: "Output Code",
            ontologyProps: getOntologyTooltipData("outputCode1"),
            ontologyHandler: props.ontologyHandler,
          }}
        />
        <AgGridColumn
          minWidth={200}
          field="outputInfo1"
          headerComponentParams={{
            primaryName: "Output Description",
            ontologyProps: getOntologyTooltipData("outputInfo1"),
            ontologyHandler: props.ontologyHandler,
          }}
        />
        <AgGridColumn
          minWidth={150}
          field="context"
          headerComponentParams={{
            primaryName: "Context",
            ontologyProps: getOntologyTooltipData("context"),
            ontologyHandler: props.ontologyHandler,
          }}
        />
        <AgGridColumn
          minWidth={150}
          field="status"
          headerComponentParams={{
            primaryName: "Status",
            ontologyProps: getOntologyTooltipData("status"),
            ontologyHandler: props.ontologyHandler,
          }}
        />
        <AgGridColumn
          minWidth={150}
          field="comments"
          headerComponentParams={{
            primaryName: "Comments",
            ontologyProps: getOntologyTooltipData("comments"),
            ontologyHandler: props.ontologyHandler,
          }}
        />
        <AgGridColumn
          minWidth={150}
          field="lastEditedAt"
          valueFormatter={(params) =>
            params.data.lastEditedAt
              ? moment(params.data.lastEditedAt).format("MM-DD-YYYY  hh:mm:ss")
              : ""
          }
          headerComponentParams={{
            primaryName: "Last Edited At",
            ontologyProps: getOntologyTooltipData("lastEditedAt"),
            ontologyHandler: props.ontologyHandler,
          }}
        />
        <AgGridColumn
          minWidth={150}
          field="lastEditedBy"
          headerComponentParams={{
            primaryName: "Last Edited By",
            ontologyProps: getOntologyTooltipData("lastEditedBy"),
            ontologyHandler: props.ontologyHandler,
          }}
        />
        <AgGridColumn
          width={75}
          cellClass="actions-button-cell text-center"
          field="actions"
          suppressMovable={true}
          sortable={false}
          filter={false}
          suppressSizeToFit={true}
          cellRenderer="actionsMenuCellRenderer"
          cellRendererParams={{
            editHandler: props.onEditCellClicked,
            deleteHandler: props.onDeleteCellClicked,
            setStatusHandler: props.onSetStatus,
          }}
        />
      </AgGridReact>
      {gridRef.current && <PaginationPanel gridApi={gridRef.current.api} />}
    </div>
  );
}

Grid.propTypes = {
  mapStatus: PropTypes.string,
  rowData: PropTypes.array,
  ontologyData: PropTypes.object,
  ontologyHandler: PropTypes.func,
  gridApi: PropTypes.func,
  onEditCellClicked: PropTypes.func,
  onDeleteCellClicked: PropTypes.func,
  onSetStatus: PropTypes.func,
};
