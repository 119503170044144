import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Dropdown from "react-bootstrap/Dropdown";

export default function ActionsMenuCellRenderer(params) {
  const setStatus = (status) => {
    const rowData = { ...params.data, status };
    params.setStatusHandler(rowData);
  };
  return (
    params.node.data.flags
      ? params.node.data.flags.deleted == true
      : params.node.data.delete
  ) ? (
    <div></div>
  ) : (
    <Dropdown align="end">
      <Dropdown.Toggle
        variant="link"
        size="sm"
        bsPrefix="shadow-none"
        title="Action Menu"
      >
        <FontAwesomeIcon
          icon={solid("ellipsis-vertical")}
          className="icon-link"
          size="xl"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="shadow">
        <Dropdown.Item
          as="button"
          className="lh-base"
          onClick={() => params.editHandler(params.node)}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          className="lh-base"
          onClick={() => params.deleteHandler(params.node)}
        >
          Delete
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          className="lh-base"
          onClick={() => setStatus("NEEDS_REVIEW")}
        >
          Set as Needs Review
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          className="lh-base"
          onClick={() => setStatus("TENTATIVE")}
        >
          Set as Tentative
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          className="lh-base"
          onClick={() => setStatus("WONT_MAP")}
        >
          {`Set as Won't Map`}
        </Dropdown.Item>
        <Dropdown.Item
          as="button"
          className="lh-base"
          onClick={() => setStatus("MAPPED")}
        >
          Set as Mapped
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
