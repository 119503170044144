import { Button } from "@uitk/react";
import PropTypes from "prop-types";

export default function BottomBarButtons(props) {
  return (
    <div>
      <div className="mb-3">
        <Button
          isDisabled={props.addDisabled}
          variant="ghost"
          onPress={props.addRowOnPress}
        >
          Add Row
        </Button>
      </div>
      <div className="d-flex flex-grow-1">
        <div className="d-flex flex-grow-1">
          <Button variant="ghost" onPress={props.backOnPress}>
            Back to Automapping
          </Button>
        </div>
        <div className="d-flex flex-grow-1 justify-content-end">
          <Button
            variant="ghost"
            isDisabled={props.draftDisabled}
            onPress={props.draftOnPress}
            className="me-2"
          >
            Save as Public Draft
          </Button>
          {(!props.reviewDisabled || props.publishDisabled) && (
            <Button
              onPress={props.reviewOnPress}
              isDisabled={props.reviewDisabled}
              className="me-2"
            >
              Submit for Review
            </Button>
          )}
          {!props.publishDisabled && (
            <Button
              variant="ghost"
              isDisabled={props.publishDisabled}
              onPress={props.moveToDraftOnPress}
              className="me-2"
            >
              Move to Draft
            </Button>
          )}
          <Button
            isDisabled={props.publishDisabled}
            onPress={props.publishOnPress}
            className="green-button"
          >
            Publish Mapping
          </Button>
        </div>
      </div>
    </div>
  );
}

BottomBarButtons.propTypes = {
  addRowOnPress: PropTypes.func,
  backOnPress: PropTypes.func,
  draftOnPress: PropTypes.func,
  publishOnPress: PropTypes.func,
  reviewOnPress: PropTypes.func,
  draftDisabled: PropTypes.bool,
  reviewDisabled: PropTypes.bool,
  publishDisabled: PropTypes.bool,
  addDisabled: PropTypes.bool,
  moveToDraftOnPress: PropTypes.func,
};
