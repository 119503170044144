import { RouteDisplay } from "../components/navigation/RouteDisplay";
import { ProgressWizard } from "../components/navigation/ProgressWizard";
import Layout from "../components/layout/Layout";
import { LoadingIndicator, Button } from "@uitk/react";
import { useState, useEffect } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import MapAttributes from "../components/MapAttributes";
import AttributeFilter from "../components/attributeFilter";
import BottomBarButtons from "../components/table/BottomBarButtons";
import AddEditRowForm from "../components/table/AddEditRowForm";
import DeleteRowForm from "../components/table/DeleteRowForm";
import { Grid } from "../components/table/Grid";
import { OntologyPopup } from "../components/table/OntologyPopup";
import { BASE_URL, PATHS } from "../utils/constants";
import useMapValueDefinition from "../hooks/useMapValueDefinition";
import AddAttributeForm from "../components/AddAttributeForm";
import useSubmissionOperations from "../hooks/useSubmissionOperations";
import SubmissionPopup from "../components/SubmissionPopup";
import Select from "react-select";

const MapValueDefinition = () => {
  const [isOpenAddEditDialog, setIsOpenAddEditDialog] = useState(false);
  const [isOpenDeleteRowDialog, setIsOpenDeleteRowDialog] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [formData, setFormData] = useState(null);
  const [formCallback, setFormCallback] = useState(null);
  const [addAttrCallback, setAddAttrCallback] = useState(null);
  const [deleteCallback, setDeleteCallback] = useState(null);
  const [isOpenOntologyDialog, setIsOpenOntologyDialog] = useState(false);
  const [ontologyPopupData, setOntologyPopupData] = useState({});
  const [addRowIdIncrementer, setAddRowIdIncrementer] = useState(1);
  const [searchValues, setSearchValues] = useState([]);
  const [mapFetching, setMapFetching] = useState(false);
  const [isOpenAddAttrDialog, setIsOpenAddAttrDialog] = useState(false);
  const [addAttrName, setAddAttrName] = useState("Synonym Source");

  // Destructure the value that the hook returns
  const {
    state: {
      ontologyData,
      attrFilters,
      attrConfig,
      mapStatus,
      mapVersion,
      rowData,
      setRowData,
      mapAttributes,
      setMapAttributes,
      tableLoading,
      setTableLoading,
      attrLoading,
      isSearchDisabled,
      setIsSearchDisabled,
      isSearchBtnDisabled,
      setIsSearchBtnDisabled,
      mapID,
    },
    fetchAttribute,
    fetchAttrConfig,
    resetFilters,
    fetchMapById,
    headerService,
  } = useMapValueDefinition();

  const {
    state: {
      submissionFinished,
      setSubmissionFinished,
      submissionErrors,
      setSubmissionErrors,
      submissionHeader,
      setSubmissionHeader,
      spawnSubmissionPopup,
      setSpawnSubmissionPopup,
      submissionSubText,
      setSubmissionSubText,
      submissionCallback,
      setSubmissionCallback,
      selectedMapId,
      setSelectedMapId,
      saveButtonDisabled,
      setSaveButtonDisabled,
      publishButtonDisabled,
      submitForReviewDisabled,
      setSubmitForReviewDisabled,
      addButtonDisabled,
    },
    handleErrorResponse,
    resetButtonOnMapStatus,
  } = useSubmissionOperations();

  const ontologyHandler = (id) => {
    populateOntology(id, ontologyData, setOntologyPopupData);
    setIsOpenOntologyDialog(true);
  };

  const closeOntologyDialog = () => {
    setOntologyPopupData({});
    setIsOpenOntologyDialog(false);
  };

  useEffect(async () => {
    let mounted = true;

    if (mounted) {
      await fetchAttrConfig();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const addRow = () => {
    // Ensure row is fully populated
    const rowData = {};
    rowData.inputCode1 = "";
    rowData.inputInfo1 = "";
    rowData.outputInfo1 = "";
    rowData.outputCode1 = "";
    rowData.context = "";
    rowData.comments = "";
    rowData.lastEditedAt = "";
    rowData.lastEditedBy = "";
    rowData.validOntologies = ontologyData.outputCode1.values;
    setFormData(rowData);
    setFormCallback(() => (response) => handleAddFormData(response));
    setIsOpenAddEditDialog(true);
  };

  const editRow = (event) => {
    if (event.data.flags && event.data.flags.deleted === true) {
      return;
    }
    gridApi.clearFocusedCell();

    // Set the form to use "edit" callback
    setFormCallback(() => (response) => handleEditFormData(response));

    // Ensure row is fully populated
    const editingRowData = getEditRowData(event.data);
    editingRowData.validOntologies = ontologyData.outputCode1.values;

    setFormData(editingRowData);
    setIsOpenAddEditDialog(true);
  };

  const deleteRow = (event) => {
    if (event.data.flags && event.data.flags.deleted === true) {
      return;
    }
    gridApi.clearFocusedCell();
    // Set the form to use "delete" callback
    setDeleteCallback(() => (response) => handleDeleteFormData(response));
    setFormData(event.data);
    setIsOpenDeleteRowDialog(true);
  };

  const closeAddEditDialog = () => {
    setIsOpenAddEditDialog(false);
  };

  const addAttr = (attrName) => {
    setAddAttrCallback(() => (response) => handleAddAttr(response));
    setAddAttrName(attrName);
    setIsOpenAddAttrDialog(true);
  };

  const closeAddAttrDialog = () => {
    setIsOpenAddAttrDialog(false);
  };

  const closeDeleteDialog = () => {
    setIsOpenDeleteRowDialog(false);
  };

  const handleAddFormData = (response) => {
    setIsOpenAddEditDialog(false);

    response["_id"] = null;
    // For new row  _id field would be null. We would use addRowId to differenciate between newly added rows.
    // we are not making use of _id as this should be set by backend api which is an objectID
    response["addRowId"] = addRowIdIncrementer;
    setAddRowIdIncrementer(addRowIdIncrementer + 1);

    // Update state
    setRowData([...rowData, response]);

    // Update the grid
    gridApi.applyTransactionAsync({ add: [response] });

    // Pin the row to the top
    addPinnedRow(response, gridApi);

    // Enable the save button
    setSaveButtonDisabled(false);

    // Disable Review Button
    setSubmitForReviewDisabled(true);
  };

  const handleEditFormData = (response) => {
    setIsOpenAddEditDialog(false);

    // Update the state of the rowData
    const newRowData = updateRow(response, rowData);
    setRowData(newRowData);

    // Update the grid
    gridApi.applyTransactionAsync({ update: [response] });

    // Add the pinned row
    addPinnedRow(response, gridApi);

    // Enable the save button
    setSaveButtonDisabled(false);

    // Disable Review Button
    setSubmitForReviewDisabled(true);
  };

  const handleDeleteFormData = (response) => {
    setIsOpenDeleteRowDialog(false);
    // Update the state of the rowData
    const newRowData = updateRow(response, rowData);
    setRowData(newRowData);

    // Update the grid
    gridApi.applyTransactionAsync({ update: [response] });

    // Add the pinned row
    addPinnedRow(response, gridApi);

    // Enable the save button
    setSaveButtonDisabled(false);

    // Disable the submit button
    setSubmitForReviewDisabled(true);
  };

  const saveAsPublicDraft = async () => {
    setSpawnSubmissionPopup(true);

    // Fetch the pinned rows
    const topRowCount = gridApi.getPinnedTopRowCount();

    const updatedRecords = getUpdatedRecords(topRowCount, gridApi);
    const updatedAttr = getUpdatedAttr(mapAttributes);
    const requestBody = {
      attributes: updatedAttr,
      mapValueDefinitions: {
        records: updatedRecords,
      },
    };

    const response = await fetch(`${BASE_URL}/maps/${selectedMapId}`, {
      method: "PATCH",
      headers: headerService.getAuthorizationHeader(),
      body: JSON.stringify(requestBody),
    });

    handleApiResponse(response);
  };

  const submitForReview = async () => {
    setSpawnSubmissionPopup(true);

    const response = await fetch(`${BASE_URL}/maps/${selectedMapId}`, {
      method: "PATCH",
      headers: headerService.getAuthorizationHeader(),
      body: JSON.stringify({ status: "REVIEW" }),
    });
    handleApiResponse(response);
  };

  const publishMap = async () => {
    setSpawnSubmissionPopup(true);

    const response = await fetch(`${BASE_URL}/maps/${selectedMapId}`, {
      method: "PATCH",
      headers: headerService.getAuthorizationHeader(),
      body: JSON.stringify({ status: "PUBLISHED" }),
    });
    handleApiResponse(response);
  };

  const moveToDraft = async () => {
    setSpawnSubmissionPopup(true);

    const response = await fetch(`${BASE_URL}/maps/${selectedMapId}`, {
      method: "PATCH",
      headers: headerService.getAuthorizationHeader(),
      body: JSON.stringify({ status: "DRAFT" }),
    });
    handleApiResponse(response);
  };

  const handleAddAttr = async (addAttrValue) => {
    setSpawnSubmissionPopup(true);
    setIsOpenAddAttrDialog(false);
    const name = addAttrName;
    const values = [addAttrValue];
    const newAttrs = [
      {
        name,
        values,
      },
    ];
    const requestBody = {
      sourceMapID: mapID,
      attributes: newAttrs,
    };

    const response = await fetch(`${BASE_URL}/maps`, {
      method: "POST",
      headers: headerService.getAuthorizationHeader(),
      body: JSON.stringify(requestBody),
    });

    handleAddAttrResponse(response);
  };

  const handleBack = () => {
    console.log("Button Pressed");
  };

  const handleFilterChange = (event, attrObj) => {
    const newSearchValues = { ...searchValues };
    newSearchValues[attrObj.name] = event.value;

    // Reset child filters
    resetFilters(attrObj, searchValues, newSearchValues);
    setSearchValues(newSearchValues);

    // Get next filter
    if (attrObj.child) {
      fetchAttribute(attrConfig._id, attrObj.child, newSearchValues);
    }

    checkSearchBtn(newSearchValues, setIsSearchBtnDisabled);
  };

  const handleSearchSuccessResponse = async (response) => {
    const json = await response.json();
    if (json.records.length > 0) {
      setSelectedMapId(json.records[0]);
      await fetchMapById(json.records[0], resetButtonOnMapStatus);
    } else {
      setSubmissionErrors([]);
      setSubmissionFinished(true);
      setSubmissionHeader("There are no matching records!");
      setSubmissionSubText("Please try with different filters.");
      setSpawnSubmissionPopup(true);
      setTableLoading(false);
      setMapFetching(false);
      setSubmissionCallback(() => async () => {
        setSpawnSubmissionPopup(false);
        setSubmissionFinished(false);
      });
    }
    setIsSearchDisabled(false);
  };

  const handleMapSearch = async () => {
    const searchArr = Object.keys(searchValues).map((key) => ({
      name: key,
      values: [searchValues[key]],
    }));

    setIsSearchDisabled(true);
    setIsSearchBtnDisabled(true);
    setTableLoading(true);
    setMapFetching(true);
    const requestBody = { attributes: searchArr };

    const response = await fetch(`${BASE_URL}/maps/search`, {
      method: "POST",
      headers: headerService.getAuthorizationHeader(),
      body: JSON.stringify(requestBody),
    });

    if (response.status === 200) {
      setGridApi(null);
      await handleSearchSuccessResponse(response);
    }
    handleErrorResponse(response, setSubmissionErrors); // need better way to reduce code complexity
    setIsSearchBtnDisabled(false);
  };

  const editAttr = (key, name, value) => {
    const newAttrs = Object.assign({}, mapAttributes, {
      [key]: { name, value, editable: true, changed: true },
    });
    setMapAttributes(newAttrs);

    // Enable the save button
    setSaveButtonDisabled(false);

    // Disable Review Button
    setSubmitForReviewDisabled(true);
  };

  const handleApiResponse = async (response) => {
    if (response.status === 200) {
      setSubmissionErrors([]);
      setSubmissionFinished(true);
      setSubmissionHeader("Your map has been saved successfully!");
      setSubmissionSubText("The table will refresh with the updated changes.");
      setSubmissionCallback(() => async () => {
        setSpawnSubmissionPopup(false);
        setSubmissionFinished(false);
        await fetchMapById(selectedMapId, resetButtonOnMapStatus);
      });
    } else {
      // State changes for all API errors
      setSubmissionFinished(true);
      setSubmissionHeader("An error has occurred.");
      setSubmissionSubText(
        "Something went wrong while submitting your request."
      );
      submissionFailed();
      handleErrorResponse(response, setSubmissionErrors); // need better way to reduce code complexity
    }
  };

  const handleAddAttrResponse = async (response) => {
    if (response.status === 200) {
      setSubmissionErrors([]);
      setSubmissionFinished(true);
      setSubmissionHeader("Attribute added and new Map created successfully!");
      setSubmissionSubText("The table will refresh with the updated changes.");
      setSubmissionCallback(() => async () => {
        setSubmissionFinished(false);
        const body = await response.json();
        setSelectedMapId(body.uuid);
        await fetchMapById(body.uuid, resetButtonOnMapStatus);
        setSpawnSubmissionPopup(false);
      });
    } else {
      // State changes for all API errors
      setSubmissionFinished(true);
      setSubmissionHeader("An error has occurred.");
      setSubmissionSubText(
        "Something went wrong while submitting your request."
      );
      submissionFailed();
      handleErrorResponse(response, setSubmissionErrors);
    }
  };

  const submissionFailed = () =>
    setSubmissionCallback(() => () => {
      setSubmissionFinished(false);
      setSpawnSubmissionPopup(false);
    });

  return (
    <>
      {" "}
      {isOpenAddEditDialog && (
        <AddEditRowForm
          isOpen={isOpenAddEditDialog}
          onClose={closeAddEditDialog}
          records={rowData}
          formCallback={formCallback}
          formData={formData}
        />
      )}
      {isOpenDeleteRowDialog && (
        <DeleteRowForm
          isOpen={isOpenAddEditDialog}
          onClose={closeDeleteDialog}
          deleteCallback={deleteCallback}
          formData={formData}
        />
      )}
      {isOpenOntologyDialog && (
        <OntologyPopup
          isOpen={isOpenOntologyDialog}
          onClose={closeOntologyDialog}
          ontologyPopupData={ontologyPopupData}
        />
      )}
      {spawnSubmissionPopup && (
        <SubmissionPopup
          isOpen={submissionFinished}
          onClose={submissionCallback}
          submissionHeader={submissionHeader}
          submissionSubText={submissionSubText}
          submissionErrors={submissionErrors}
        />
      )}
      {isOpenAddAttrDialog && (
        <AddAttributeForm
          isOpen={isOpenAddAttrDialog}
          onClose={closeAddAttrDialog}
          attrName={addAttrName}
          addAttrCallback={addAttrCallback}
        />
      )}
      <Layout>
        <div className="grid">
          <RouteDisplay
            pageRouteDisplay={[
              PATHS.mapManagement,
              PATHS.editMap,
              PATHS.editMapMapValueDefinition,
            ]}
          />
          <div className="row">
            <div className="col">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              euismod bibendum laoreet. Proin gravida dolor sit amet lacus
              accumsan et viverra justo commodo. Proin sodales pulvinar tempor.
              Cum sociis natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
              vulputate, felis tellus mollis orci, sed rhoncus sapien nunc eget
              odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Aenean euismod bibendum laoreet.
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ProgressWizard progress={4}></ProgressWizard>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2>Map Lookup</h2>
            </div>
          </div>
          <>
            {attrLoading ? (
              <div className="row align-items-start">
                <div className="col-2">
                  <div className="dropdowns">
                    <label>{"Map Lookup"}</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={true}
                      placeholder="Loading, Please wait..."
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="row align-items-start">
                {attrFilters.map((filter) => (
                  <AttributeFilter
                    key={filter._id}
                    isSearchDisabled={isSearchDisabled}
                    filter={filter}
                    onFilterChange={handleFilterChange}
                  />
                ))}
                <div className="col mt-auto">
                  <Button
                    type="submit"
                    variant="ghost"
                    onPress={handleMapSearch}
                    isDisabled={isSearchBtnDisabled}
                  >
                    Search
                  </Button>
                </div>
              </div>
            )}
          </>
          {mapFetching ? (
            tableLoading ? (
              <div className="row">
                <div className="col">
                  <div className="uitoolkit-spinner-wrapper map-loading">
                    {" "}
                    <LoadingIndicator
                      size={"l"}
                      loading={true}
                      displayOverlay={true}
                      centerSpinner={true}
                      loadingText="Loading Map Value Definition..."
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col">
                    <div className="map-title-bar">
                      <h2>Map Value Definition</h2>
                      <div className="map-metadata">
                        <div className="badge rounded-pill bg-primary map-status py-2 px-3">
                          {mapStatus}
                        </div>
                        <div className="badge rounded-pill bg-primary bg-secondary version ms-1 py-2 px-3">
                          v{mapVersion}
                        </div>
                      </div>
                    </div>
                    <MapAttributes
                      attributes={mapAttributes}
                      editAttr={editAttr}
                      addAttrOnPress={addAttr}
                      mapStatus={mapStatus}
                    ></MapAttributes>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Grid
                      mapStatus={mapStatus}
                      rowData={rowData}
                      ontologyData={ontologyData}
                      ontologyHandler={ontologyHandler}
                      gridApi={(api) => setGridApi(api)}
                      onEditCellClicked={editRow}
                      onDeleteCellClicked={deleteRow}
                      onSetStatus={handleEditFormData}
                    ></Grid>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <BottomBarButtons
                      addRowOnPress={addRow}
                      backOnPress={handleBack}
                      draftOnPress={saveAsPublicDraft}
                      draftDisabled={saveButtonDisabled}
                      publishOnPress={publishMap}
                      publishDisabled={publishButtonDisabled}
                      reviewOnPress={submitForReview}
                      reviewDisabled={submitForReviewDisabled}
                      addDisabled={addButtonDisabled}
                      moveToDraftOnPress={moveToDraft}
                    />
                  </div>
                </div>
              </>
            )
          ) : (
            ""
          )}{" "}
        </div>
      </Layout>
    </>
  );
};

function getEditRowData(data) {
  const editingRowData = data;
  editingRowData.inputCode1 = checkRow(editingRowData.inputCode1);
  editingRowData.inputInfo1 = checkRow(editingRowData.inputInfo1);
  editingRowData.outputInfo1 = checkRow(editingRowData.outputInfo1);
  editingRowData.outputCode1 = checkRow(editingRowData.outputCode1);
  editingRowData.context = checkRow(editingRowData.context);
  editingRowData.comments = checkRow(editingRowData.comments);
  return editingRowData;
}

function checkRow(row) {
  return !row ? "" : row;
}

function addPinnedRow(row, gridApi) {
  const pinnedRowCount = gridApi.getPinnedTopRowCount();
  const rowsToPin = [row];

  for (let i = 0; i < pinnedRowCount; i++) {
    const pinnedRow = gridApi.getPinnedTopRow(i).data;
    // If the row with this id is already there, remove it
    if (row._id == null) {
      // Check for newly added rows which dont have _id value set
      if (pinnedRow.addRowId !== row.addRowId) {
        rowsToPin.push(pinnedRow);
      }
    } else if (pinnedRow._id !== row._id) {
      rowsToPin.push(pinnedRow);
    }
  }

  gridApi.setPinnedTopRowData(rowsToPin);
}

function updateRow(response, rowData) {
  const newRowData = [];
  rowData.forEach((element) => {
    if (element._id === response._id) {
      newRowData.push(response);
    } else {
      newRowData.push(element);
    }
  });

  return newRowData;
}

function getUpdatedRecords(topRowCount, gridApi) {
  const updatedRecords = [];
  for (let i = 0; i < topRowCount; i++) {
    updatedRecords.push(gridApi.getPinnedTopRow(i).data);
  }
  return updatedRecords;
}

function getUpdatedAttr(attrs) {
  const updatedAttr = Object.keys(attrs).map((key) => {
    const obj = attrs[key];

    if (obj.changed) {
      return {
        name: obj.name,
        values: [obj.value],
      };
    }

    return null;
  });
  return updatedAttr.filter((x) => x !== null);
}

function populateOntology(id, ontologyData, setOntologyPopupData) {
  for (const key in ontologyData) {
    if (ontologyData[key]._id === id) {
      setOntologyPopupData(ontologyData[key]);
    }
  }
}

function checkSearchBtn(searchVals, setIsSearchBtnDisabled) {
  if (Object.keys(searchVals).length) {
    setIsSearchBtnDisabled(false);
  } else {
    setIsSearchBtnDisabled(true);
  }
}

export default MapValueDefinition;
